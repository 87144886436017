import {Controller} from "stimulus"
import $ from 'jquery';
import {setSessionVar} from "./sessionStorage_controller";
import flatpickr from "flatpickr";
import {Spanish} from "flatpickr/dist/l10n/es";
import {english} from "flatpickr/dist/l10n/default";
import {calc_price_by_product, create_array_for_product} from "./b2b_controller";

require ("select2/dist/css/select2")
require ("select2-bootstrap-theme/dist/select2-bootstrap")

let destination_by_offices = {}
let url_dealer, url_collect_place, url_mark, url_model,
    verbose_dealer, verbose_collect,
    verbose_mark, verbose_model, recogida, flatpick_lang,
    date_disabled = [], product_details, min_reservation, max_reservation, categories = [];
let lang_value = '';
let location_value = '';

let categories_details = (target) => new Promise ((resolve, reject) => {
    categories.services.map ((child, key) => {
        if (child !== null) {
            if (child.code === target) {
                resolve (child);
            }
        }
    });
})
let disable_dates = () => new Promise ((resolve, reject) => {
    $.ajax ({
        url: location.origin + "/admin/disable_dates/disabled",
        data: {
            format: "json"
        },
        type: "GET",
        success: function (data) {
            resolve (data);
        }
    })
})

let verify_season = (date) => new Promise ((resolve, reject) => {

    $.ajax ({
        url: location.origin + "/verify_season",
        data: {
            date: date,
            category: sessionStorage.getItem ("category") || create_array_for_product('category','','show')
        },
        type: "GET",
        success: function (data) {
            if (data.success) {
                resolve (data.data);
            } else {
                // console.log (data);
            }
        }

    })
})

export default class extends Controller {

    initialize () {
        product_details = $ ('#product_details')[0].dataset;

        let destino;
        let collect_place_pr = $ ('#collect_place_pr');
        let delivery_place_pr = $ ('#delivery_place_pr');
        let destination_pr = $ ('#destination_pre_reserva');
        let date_collect_pr = $ ('#date_collect_pr');
        let date_delivery_pr = $ ('#date_delivery_pr');
        let time_collect_pr = $ ('#time_collect_pr');
        let time_delivery_pr = $ ('#time_delivery_pr');
        let has_drivers_pr = $ ('#has_drivers_pr');
        let drivers_count = $ ('#drivers_count');
        let drivers_pr = $ ('#drivers_pr');
        let supplement_insurance_pr = $ ('#supplement_insurance_pr');
        time_delivery_pr.prop ('disabled', true);
        time_collect_pr.prop ('disabled', true);

        //REX CAR
        let collect_place_car_lux_pr = $ ('#collect_place_car_lux_pr');
        let delivery_place_car_lux_pr = $ ('#delivery_place_car_lux_pr');
        let destination_car_lux_pr = $ ('#destination_car_lux_pr');
        let date_collect_car_lux_pr = $ ('#date_collect_car_lux_pr');
        let date_delivery_car_lux_pr = $ ('#date_delivery_car_lux_pr');
        let time_collect_car_lux_pr = $ ('#time_collect_car_lux_pr');
        let time_delivery_car_lux_pr = $ ('#time_delivery_car_lux_pr');
        let has_drivers_car_lux_pr = $ ('#has_drivers_car_lux_pr');
        let drivers_count_car_lux_pr = $ ('#drivers_count_car_lux_pr');
        let drivers_car_lux_pr = $ ('#drivers_car_lux_pr');
        let supplement_insurance_car_lux_pr = $ ('#supplement_insurance_car_lux_pr');
        time_delivery_car_lux_pr.prop ('disabled', true);
        time_collect_car_lux_pr.prop ('disabled', true);

        //BUSTOUR
        let destination_bustour_details = $ ('#destination_bustour_details');
        let date_collect_bustour_details = $ ('#date_collect_bustour_details');
        let amount_bustour_details = $ ('#amount_bustour_details');
        date_collect_bustour_details.prop ('disabled', true);
        amount_bustour_details.prop ('disabled', true);
        //END BUSTOUR

        //SHUTTLE
        let destination_shuttle_details = $ ('#destination_shuttle_details');
        let date_collect_shuttle_details = $ ('#date_collect_shuttle_details');
        let amount_shuttle_details = $ ('#amount_shuttle_details');
        date_collect_shuttle_details.prop ('disabled', true);
        amount_shuttle_details.prop ('disabled', true);
        //END SHUTTLE

        // MOTOS
        let destination_bikes_details = $ ('#destination_bikes_details');
        let collect_place_bikes_details = $ ('#collect_place_bikes_details').select2 ({
            theme: "bootstrap",
            placeholder: I18n.t ('global.main.form.select_office')
        });
        let modality_bikes_details = $ ('#modality_bikes_details').select2 ({
            theme: "bootstrap",
            placeholder: I18n.t ('global.main.form.select_modality')
        });
        let drivers_bikes_details = $ ('#drivers_bikes_details').select2 ({
            theme: "bootstrap",
            placeholder: I18n.t ('global.main.form.select_drivers'),
            allowClear: true
        });
        let has_drivers_bikes_details = $ ('#has_drivers_bikes_details');
        let supplement_insurance_bikes_details = $ ('#supplement_insurance_bikes_details');
        // END MOTOS

        //BUS TRAVEL
        let out_bus_details = $ ('#out_bus_details');
        let destination_bus_details = $ ('#destination_bus_details').select2 ({
            theme: "bootstrap",
            placeholder: I18n.t ('global.main.form.select_destiny')
        }).prop ('disabled', true);
        let bus_location_details = $ ('#bus_location_details').prop ('disabled', true).on ('change', function (e) {
            location_value = e.target.value;
        });
        let date_collect_bus_details = $ ('#date_collect_bus_details').prop ('disabled', true);
        let time_collect_bus_details = $ ('#time_collect_bus_details').on ('change', function (e) {
            lang_value = e.target.value;
            let text = '';
            if (!location_value.isEmpty () && !lang_value.isEmpty ()) {
                text = `Ubicado en: ${location_value}, Hora de recogida: ${e.target.value}`;
            } else {
                if (!location_value.isEmpty ()) {
                    text = `Ubicado en: ${location_value}`;
                }
                if (!lang_value.isEmpty ()) {
                    text = `Hora de recogida: ${e.target.value}`;
                }
            }
            create_array_for_product ('comment', text);

        }).prop ('disabled', true)
        //END BUS TRAVEL

        disable_dates ().then (
            dd => {
                date_disabled = dd;
            }
        )

        switch (I18n.locale) {
            case 'es':
                flatpick_lang = Spanish;
                break;
            case 'en':
                flatpick_lang = english;
                break;
        }

        //CHOFERES ADICIONALES
        create_array_for_product ('drivers', 0);

        has_drivers_pr.on ('change', function (e) {
            if (e.target.checked) {

                drivers_count.removeClass ('ocultar');
                drivers_pr.select2 ({
                    theme: "bootstrap",
                    placeholder: I18n.t ('global.main.form.select_drivers'),
                    data: [
                        {
                            id: '',
                        },
                        {
                            id: 1,
                            text: '1'
                        },
                        {
                            id: 2,
                            text: '2'
                        }
                    ]
                }).on ('change', function (e) {
                    create_array_for_product ('drivers', e.target.value);
                    setSessionVar ('drivers', e.target.value, 'add', false).then ();
                    calc_price_by_product(create_array_for_product('','','all')).then();
                })
            } else {
                create_array_for_product ('drivers', 0);
                setSessionVar ('drivers', '0', 'add', false).then ();
                drivers_pr.val (null).trigger ('change');
                drivers_count.addClass ('ocultar');
                calc_price_by_product(create_array_for_product('','','all')).then();
            }
        })
        has_drivers_bikes_details.on ('change', function (e) {
            let drivers_count_bikes = $ ('#drivers_count_bikes');
            if (e.target.checked) {
                drivers_count_bikes.removeClass ('ocultar');
                drivers_bikes_details.select2 ({
                    theme: "bootstrap",
                    placeholder: I18n.t ('global.main.form.select_drivers'),
                    data: [
                        {
                            id: ''
                        },
                        {
                            id: 1,
                            text: '1'
                        },
                        {
                            id: 2,
                            text: '2'
                        }
                    ]
                }).on ('change', function (e) {
                    create_array_for_product ('drivers', e.target.value);
                    setSessionVar ('drivers', e.target.value, 'add', false).then ();
                    calc_price_by_product(create_array_for_product('','','all')).then();
                })
            } else {
                create_array_for_product ('drivers', 0);
                setSessionVar ('drivers', '0', 'add', false).then ();
                drivers_bikes_details.val (null).trigger ('change');
                drivers_count_bikes.addClass ('ocultar');
                calc_price_by_product(create_array_for_product('','','all')).then();
            }
        })
        has_drivers_car_lux_pr.on ('change', function (e) {
            if (e.target.checked) {
                drivers_count_car_lux_pr.removeClass ('ocultar');
                drivers_car_lux_pr.select2 ({
                    theme: "bootstrap",
                    placeholder: I18n.t ('global.main.form.select_drivers'),
                    data: [
                        {
                            id: ''
                        },
                        {
                            id: 1,
                            text: '1'
                        },
                        {
                            id: 2,
                            text: '2'
                        }
                    ]
                }).on ('change', function (e) {
                    create_array_for_product ('drivers', e.target.value);
                    setSessionVar ('drivers', e.target.value, 'add', false).then ();
                    calc_price_by_product(create_array_for_product('','','all')).then();
                })
            } else {
                create_array_for_product ('drivers', 0);
                setSessionVar ('drivers', '0', 'add', false).then ();
                drivers_car_lux_pr.val (null).trigger ('change');
                drivers_count_car_lux_pr.addClass ('ocultar');
                calc_price_by_product(create_array_for_product('','','all')).then();
            }
        })
        //FIN DE CHOFERES ADICIONALES

        //SUPLEMENTO DE EDAD
        // setSessionVar('supplement_insurance','0','add',false).then();
        create_array_for_product ('supplement_insurance', 0);
        supplement_insurance_pr.on ('change', function (e) {
            if (e.target.checked) {
                create_array_for_product ('supplement_insurance', 1);
                // setSessionVar('supplement_insurance','1','add',false).then();
            } else {
                create_array_for_product ('supplement_insurance', 0);
                // setSessionVar('supplement_insurance','0','add',false).then();
            }
            calc_price_by_product(create_array_for_product('','','all')).then();
        })
        supplement_insurance_car_lux_pr.on ('change', function (e) {
            if (e.target.checked) {
                create_array_for_product ('supplement_insurance', 1);
                // setSessionVar('supplement_insurance','1','add',false).then();
            } else {
                create_array_for_product ('supplement_insurance', 0);
                // setSessionVar('supplement_insurance','0','add',false).then();
            }
            calc_price_by_product(create_array_for_product('','','all')).then();
        })
        supplement_insurance_bikes_details.on ('change', function (e) {
            if (e.target.checked) {
                create_array_for_product ('supplement_insurance', 1);
                // setSessionVar('supplement_insurance','1','add',false).then();
            } else {
                create_array_for_product ('supplement_insurance', 0);
                // setSessionVar('supplement_insurance','0','add',false).then();
            }
            calc_price_by_product(create_array_for_product('','','all')).then();
        })
        //FIN DE SUPLEMENTO DE EDAD

        collect_place_pr.select2 ({
            theme: "bootstrap"
        }).prop ("disabled", true);
        destination_pr.select2 ({
            theme: "bootstrap"
        });
        delivery_place_pr.select2 ({
            theme: "bootstrap"
        })

        //REX CAR
        collect_place_car_lux_pr.select2 ({
            theme: "bootstrap"
        }).prop ("disabled", true);
        destination_car_lux_pr.select2 ({
            theme: "bootstrap"
        });
        delivery_place_car_lux_pr.select2 ({
            theme: "bootstrap"
        })
        //END REX CAR
        $.ajax (
            {
                url: `${location.origin}/${sessionStorage.getItem ('locale')}/preferences/to/general.json`,
                data: {format: "json"},
                type: "get",
                success: function (data) {

                    data.map ((elem, key) => {
                        if (data.hasOwnProperty (key)) {
                            switch (elem.key.text) {
                                case "category_url":
                                    url_dealer = elem.id
                                    verbose_dealer = elem.verbose
                                    break;
                                case "url_collect_place":
                                    url_collect_place = elem.id
                                    verbose_collect = elem.verbose
                                    break;
                                case "url_mark":
                                    url_mark = elem.id
                                    verbose_mark = elem.verbose
                                    break;
                                case "url_model":
                                    url_model = elem.id
                                    verbose_model = elem.verbose
                                    break;
                            }

                        }
                    })
                },
                complete: function (data) {
                    // destination_pr.select2 ({
                    //     theme: "bootstrap",
                    //     placeholder: I18n.t ('global.main.form.select_destiny'),
                    //     ajax: {
                    //         url: `${location.origin}/${sessionStorage.getItem ('locale')}/destination_by_service`,
                    //         data: {
                    //             url: url_dealer,
                    //             category: product_details.code,
                    //             seller: sessionStorage.getItem ('seller'),
                    //             form_type: product_details.type
                    //         },
                    //         type: "GET",
                    //         processResults: function (data, params) {
                    //             let result = [];
                    //             data.map ((elem, key) => {
                    //
                    //                 result.push ({
                    //                     text: elem.name,
                    //                     id: elem.code + '|' + elem.id,
                    //                 });
                    //             })
                    //
                    //             return {
                    //                 results: result
                    //             }
                    //
                    //         }
                    //     }
                    // }).on ('change', function (e) {
                        //LIMPIAR SELECT2
                        // collect_place_pr.empty ();
                        // delivery_place_pr.empty ();
                        //FIN DE LIMPIEZA
                        // let split_destination = e.target.value.split ('|');

                        // create_array_for_product ('product_type', product_details.type);
                        // create_array_for_product ('destination', split_destination[1]);
                        // create_array_for_product ('destination_id', split_destination[0]);
                        // create_array_for_product ('text_destination', $ (e.target).select2 ('data')[0].text);
                        // create_array_for_product ('category', product_details.code);
                        // create_array_for_product ('text_category', product_details.name);


                        collect_place_pr.select2 ({
                            theme: "bootstrap",
                            placeholder: I18n.t ('global.main.form.select_office'),
                            ajax: {
                                url: `${location.origin}/${sessionStorage.getItem ('locale')}/office_by_destination`,
                                data: function (params) {
                                    return {
                                        url: url_collect_place,
                                        // destination: split_destination[1],
                                        type: product_details.type,
                                        search: params.term,
                                        page: params.page || 1
                                    }
                                },
                                type: "GET",
                                processResults: function (data, params) {
                                    let result = [];
                                    data.offices.map ((elem, key) => {
                                        let aux = {
                                            text: elem.dealer_name,
                                            children: []
                                        };
                                        if (elem.offices.length > 0) {
                                            elem.offices.map ((child, number) => {
                                                if (child.active) {
                                                    destination_by_offices[child.code] = {
                                                        destination_id: child.destination_id,
                                                        destination_code: child.destination_code,
                                                        destination_name: child.destination_name
                                                    }
                                                    aux.children.push ({
                                                        id: child.code + '-dealer:' + elem.dealer_code + '-dealer_name:' + elem.dealer_name + '-full_time:' + child.full_time + '-open_time:' + child.open_time + '-close_time:' + child.close_time,
                                                        text: child.name
                                                    });
                                                }

                                            })

                                            result.push (aux);
                                        }
                                    })

                                    return {
                                        results: result
                                    }
                                }
                            }
                        })
                            .prop ("disabled", false)
                            .on ('change', function (e) {
                                recogida = e.target.value;
                                recogida = recogida.split ('-');

                                create_array_for_product ('product_type', product_details.type);
                                create_array_for_product ('destination', destination_by_offices[recogida[0]].destination_code);
                                create_array_for_product ('destination_id', destination_by_offices[recogida[0]].destination_id);
                                create_array_for_product ('text_destination',destination_by_offices[recogida[0]].destination_name);
                                create_array_for_product ('category', product_details.code);
                                create_array_for_product ('text_category', product_details.name);

                                $.ajax ({
                                    url: `${location.origin}/${sessionStorage.getItem ('locale')}/get_product`,
                                    data: {
                                        dealer: recogida[1].split ('dealer:')[1],
                                        category: product_details.code,
                                        form_type: product_details.type
                                    },
                                    type: 'GET',
                                    success: function (result) {

                                        min_reservation = result.min_length_reservation;
                                        max_reservation = result.max_length_reservation;

                                        create_array_for_product ('pick_up_place', recogida[0]);
                                        create_array_for_product ('dealer', recogida[1].split ('dealer:')[1]);
                                        create_array_for_product ('dealer_name', recogida[2].split ('dealer_name:')[1]);
                                        create_array_for_product ('collect_place_text', $ (e.target).select2 ('data')[0].text);

                                        setSessionVar ('same_hours', result.same_hours, 'add', false);

                                        let helper = $ (collect_place_pr[0].parentNode).find ('.helper-text');
                                        let tootltip_button = $ (collect_place_pr[0].parentNode).find ('.tooltip-button');
                                        let text_helper = '';
                                        date_collect_pr.prop ('disabled', false);
                                        time_collect_pr.prop ('disabled', false);
                                        if (result.same_hours !== true) {
                                            time_delivery_pr.prop ('disabled', false);
                                        }

                                        let full_time = recogida[3].split ('full_time:')[1];
                                        if (full_time === "false") {
                                            flatpickr (".timepicker", {
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                altFormat: "H:i",
                                                time_24hr: true,
                                                locale: flatpick_lang,
                                                disableMobile: "true",
                                                minTime: recogida[4].split ('open_time:')[1] + ":00",
                                                maxTime: recogida[5].split ('close_time:')[1] + ":00",
                                            })

                                            let from = recogida[4].split ('open_time:')[1] + ":00am";
                                            let to = (parseInt (recogida[5].split ("close_time:")[1]) - 12) + ":00pm";
                                            text_helper = I18n.t ('global.main.form.work_by_hours', {
                                                from: from,
                                                to: to
                                            });
                                        } else {
                                            flatpickr (".timepicker", {
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                time_24hr: true,
                                                altFormat: "H:i",
                                                locale: flatpick_lang,
                                                disableMobile: "true",
                                            })
                                            text_helper = I18n.t ('global.main.form.work_all_day');
                                        }

                                        helper.text (text_helper);
                                        tootltip_button[0].classList.remove ('hide-tooltip');

                                        flatpickr ("#date_collect_pr", {
                                            altInput: true,
                                            altFormat: "d-m-Y",
                                            dateFormat: "Y-m-d",
                                            disable: date_disabled,
                                            minDate: new Date ().fp_incr (parseInt (min_reservation)),
                                            maxDate: new Date ().fp_incr (180),
                                            locale: flatpick_lang
                                        });
                                    }
                                })

                                calc_price_by_product(create_array_for_product('','','all')).then();
                            })

                        delivery_place_pr.select2 ({
                            theme: "bootstrap",
                            placeholder: I18n.t ('global.main.form.select_office'),
                            ajax: {
                                url: `${location.origin}/${sessionStorage.getItem ('locale')}/office_by_destination`,
                                data: function (params) {
                                    return {
                                        url: url_collect_place,
                                        type: product_details.type,
                                        search: params.term,
                                        page: params.page || 1,
                                        delivery: true
                                    }
                                },
                                type: "GET",
                                processResults: function (data, params) {
                                    let result = [];
                                    data.offices.map ((elem, key) => {
                                        let aux = {
                                            text: elem.dealer_name,
                                            children: []
                                        };
                                        if (elem.offices.length > 0) {
                                            elem.offices.map ((child, number) => {
                                                if (child.active) {
                                                    aux.children.push ({
                                                        id: child.code + '-dealer:' + elem.dealer_code + '-dealer_name:' + elem.dealer_name + '-full_time:' + child.full_time + '-open_time:' + child.open_time + '-close_time:' + child.close_time,
                                                        text: child.name
                                                    });
                                                }

                                            })

                                            result.push (aux);
                                        }
                                    })

                                    return {
                                        results: result
                                    }
                                }
                            }
                        })
                            .prop ("disabled", false)
                            .on ('change', function (e) {
                                let entrega;
                                entrega = e.target.value;
                                entrega = entrega.split ('-');

                                create_array_for_product ('drop_off_place', entrega[0]);
                                create_array_for_product ('delivery_place_text', $ (e.target).select2 ('data')[0].text);

                                let helper_delivery = $ (delivery_place_pr[0].parentNode).find ('.helper-text');
                                let tootltip_button_delivery = $ (delivery_place_pr[0].parentNode).find ('.tooltip-button');
                                let from = entrega[4].split ('open_time:')[1] + ":00am";
                                let to = (parseInt (entrega[5].split ("close_time:")[1]) - 12) + ":00pm";
                                let text_helper = I18n.t ('global.main.form.work_by_hours', {
                                    from: from,
                                    to: to
                                });

                                helper_delivery.text (text_helper);
                                tootltip_button_delivery[0].classList.remove ('hide-tooltip');

                                calc_price_by_product(create_array_for_product('','','all')).then();
                            })
                    // })

                    //ACCIONES PARA REX CAR

                    // destination_car_lux_pr.select2 ({
                    //     theme: "bootstrap",
                    //     placeholder: I18n.t ('global.main.form.select_destiny'),
                    //     ajax: {
                    //         url: `${location.origin}/${sessionStorage.getItem ('locale')}/destination_by_service`,
                    //         data: {
                    //             url: url_dealer,
                    //             category: product_details.code,
                    //             seller: sessionStorage.getItem ('seller'),
                    //             form_type: product_details.type
                    //         },
                    //         type: "GET",
                    //         processResults: function (data, params) {
                    //             let result = [];
                    //             data.map ((elem, key) => {
                    //
                    //                 result.push ({
                    //                     text: elem.name,
                    //                     id: elem.code + '|' + elem.id,
                    //                 });
                    //             })
                    //
                    //             return {
                    //                 results: result
                    //             }
                    //
                    //         }
                    //     }
                    // }).on ('change', function (e) {
                    //     //LIMPIAR SELECT2
                    //     collect_place_car_lux_pr.empty ();
                    //     delivery_place_car_lux_pr.empty ();
                    //     //FIN DE LIMPIEZA
                    //     let split_destination = e.target.value.split ('|');
                    //
                    //     create_array_for_product ('product_type', product_details.type);
                    //     create_array_for_product ('destination', split_destination[1]);
                    //     create_array_for_product ('destination_id', split_destination[0]);
                    //     create_array_for_product ('text_destination', $ (e.target).select2 ('data')[0].text);
                    //     create_array_for_product ('category', product_details.code);
                    //     create_array_for_product ('text_category', product_details.name);


                        collect_place_car_lux_pr.select2 ({
                            theme: "bootstrap",
                            placeholder: I18n.t ('global.main.form.select_office'),
                            ajax: {
                                url: `${location.origin}/${sessionStorage.getItem ('locale')}/office_by_destination`,
                                data: function (params) {
                                    return {
                                        url: url_collect_place,
                                        destination: split_destination[1],
                                        type: product_details.type,
                                        search: params.term,
                                        page: params.page || 1
                                    }
                                },
                                type: "GET",
                                processResults: function (data, params) {
                                    let result = [];
                                    data.offices.map ((elem, key) => {
                                        let aux = {
                                            text: elem.dealer_name,
                                            children: []
                                        };
                                        if (elem.offices.length > 0) {
                                            elem.offices.map ((child, number) => {
                                                if (child.active) {
                                                    destination_by_offices[child.code] = {
                                                        destination_id: child.destination_id,
                                                        destination_code: child.destination_code,
                                                        destination_name: child.destination_name
                                                    }

                                                    aux.children.push ({
                                                        id: child.code + '-dealer:' + elem.dealer_code + '-dealer_name:' + elem.dealer_name + '-full_time:' + child.full_time + '-open_time:' + child.open_time + '-close_time:' + child.close_time,
                                                        text: child.name
                                                    });
                                                }

                                            })

                                            result.push (aux);
                                        }
                                    })

                                    return {
                                        results: result
                                    }
                                }
                            }
                        })
                            .prop ("disabled", false)
                            .on ('change', function (e) {
                                recogida = e.target.value;
                                recogida = recogida.split ('-');

                                create_array_for_product ('product_type', product_details.type);
                                create_array_for_product ('destination', destination_by_offices[recogida[0]].destination_code);
                                create_array_for_product ('destination_id', destination_by_offices[recogida[0]].destination_id);
                                create_array_for_product ('text_destination',destination_by_offices[recogida[0]].destination_name);
                                create_array_for_product ('category', product_details.code);
                                create_array_for_product ('text_category', product_details.name);

                                $.ajax ({
                                    url: `${location.origin}/${sessionStorage.getItem ('locale')}/get_product`,
                                    data: {
                                        dealer: recogida[1].split ('dealer:')[1],
                                        category: product_details.code,
                                        form_type: product_details.type
                                    },
                                    type: 'GET',
                                    success: function (result) {

                                        min_reservation = result.min_length_reservation;
                                        max_reservation = result.max_length_reservation;

                                        create_array_for_product ('pick_up_place', recogida[0]);
                                        create_array_for_product ('dealer', recogida[1].split ('dealer:')[1]);
                                        create_array_for_product ('dealer_name', recogida[2].split ('dealer_name:')[1]);
                                        create_array_for_product ('collect_place_text', $ (e.target).select2 ('data')[0].text);

                                        setSessionVar ('same_hours', result.same_hours, 'add', false);

                                        let helper = $ (collect_place_car_lux_pr[0].parentNode).find ('.helper-text');
                                        let tootltip_button = $ (collect_place_car_lux_pr[0].parentNode).find ('.tooltip-button');
                                        let text_helper = '';
                                        date_collect_pr.prop ('disabled', false);
                                        time_collect_pr.prop ('disabled', false);
                                        if (result.same_hours !== true) {
                                            time_delivery_pr.prop ('disabled', false);
                                        }

                                        let full_time = recogida[3].split ('full_time:')[1];
                                        if (full_time === "false") {
                                            flatpickr (".timepicker", {
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                altFormat: "H:i",
                                                time_24hr: true,
                                                locale: flatpick_lang,
                                                disableMobile: "true",
                                                minTime: recogida[4].split ('open_time:')[1] + ":00",
                                                maxTime: recogida[5].split ('close_time:')[1] + ":00",
                                            })

                                            let from = recogida[4].split ('open_time:')[1] + ":00am";
                                            let to = (parseInt (recogida[5].split ("close_time:")[1]) - 12) + ":00pm";
                                            text_helper = I18n.t ('global.main.form.work_by_hours', {
                                                from: from,
                                                to: to
                                            });
                                        } else {
                                            flatpickr (".timepicker", {
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                time_24hr: true,
                                                altFormat: "H:i",
                                                locale: flatpick_lang,
                                                disableMobile: "true",
                                            })
                                            text_helper = I18n.t ('global.main.form.work_all_day');
                                        }

                                        helper.text (text_helper);
                                        tootltip_button[0].classList.remove ('hide-tooltip');

                                        flatpickr ("#date_collect_car_lux_pr", {
                                            altInput: true,
                                            altFormat: "d-m-Y",
                                            dateFormat: "Y-m-d",
                                            disable: date_disabled,
                                            minDate: new Date ().fp_incr (parseInt (min_reservation)),
                                            maxDate: new Date ().fp_incr (180),
                                            locale: flatpick_lang
                                        });
                                    }
                                })

                                calc_price_by_product(create_array_for_product('','','all')).then();
                            })

                        delivery_place_car_lux_pr.select2 ({
                            theme: "bootstrap",
                            placeholder: I18n.t ('global.main.form.select_office'),
                            ajax: {
                                url: `${location.origin}/${sessionStorage.getItem ('locale')}/office_by_destination`,
                                data: function (params) {
                                    return {
                                        url: url_collect_place,
                                        type: product_details.type,
                                        search: params.term,
                                        page: params.page || 1
                                    }
                                },
                                type: "GET",
                                processResults: function (data, params) {
                                    let result = [];
                                    data.offices.map ((elem, key) => {
                                        let aux = {
                                            text: elem.dealer_name,
                                            children: []
                                        };
                                        if (elem.offices.length > 0) {
                                            elem.offices.map ((child, number) => {
                                                if (child.active) {
                                                    aux.children.push ({
                                                        id: child.code + '-dealer:' + elem.dealer_code + '-dealer_name:' + elem.dealer_name + '-full_time:' + child.full_time + '-open_time:' + child.open_time + '-close_time:' + child.close_time,
                                                        text: child.name
                                                    });
                                                }

                                            })

                                            result.push (aux);
                                        }
                                    })

                                    return {
                                        results: result
                                    }
                                }
                            }
                        })
                            .prop ("disabled", false)
                            .on ('change', function (e) {
                                let entrega;
                                entrega = e.target.value;
                                entrega = entrega.split ('-');

                                create_array_for_product ('drop_off_place', entrega[0]);
                                create_array_for_product ('delivery_place_text', $ (e.target).select2 ('data')[0].text);

                                let helper_delivery = $ (delivery_place_car_lux_pr[0].parentNode).find ('.helper-text');
                                let tootltip_button_delivery = $ (delivery_place_car_lux_pr[0].parentNode).find ('.tooltip-button');
                                let from = entrega[4].split ('open_time:')[1] + ":00am";
                                let to = (parseInt (entrega[5].split ("close_time:")[1]) - 12) + ":00pm";

                                let text_helper = I18n.t ('global.main.form.work_by_hours', {
                                    from: from,
                                    to: to
                                });

                                helper_delivery.text (text_helper);
                                tootltip_button_delivery[0].classList.remove ('hide-tooltip');

                                date_collect_car_lux_pr.prop('disabled',false);
                                time_collect_car_lux_pr.prop('disabled',false);

                                calc_price_by_product(create_array_for_product('','','all')).then();
                            })
                    // })
                    date_collect_car_lux_pr.on ('change', (e) => {

                        //VERIFICAR TEMPORADA
                        verify_season (e.target.value).then (
                            vs => {
                                date_delivery_pr.prop ('disabled', false);

                                if (sessionStorage.getItem ('same_hours') !== 'true') {
                                    $ ('#time_delivery_pr').prop ('disabled', false);
                                }

                                let max_date = 0;
                                let min_date = 0;

                                if (vs.length > 0) {
                                    max_date = parseInt (vs[0].max) + 1;
                                    min_date = parseInt (vs[0].min) + 1;
                                } else {
                                    max_date = parseInt (max_reservation) + 1;
                                    min_date = parseInt (min_reservation) + 1;
                                }

                                flatpickr ("#date_delivery_car_lux_pr", {
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    disableMobile: "true",
                                    disable: date_disabled,
                                    minDate: new Date (e.target.value).fp_incr (min_date),
                                    maxDate: new Date (e.target.value).fp_incr (max_date),
                                    locale: flatpick_lang
                                })
                            }
                        );

                        calc_price_by_product(create_array_for_product('','','all')).then();
                    })
                    time_collect_car_lux_pr.on ('change', (e) => {
                        if (sessionStorage.getItem ('same_hours') === 'true') {
                            time_delivery_car_lux_pr[0].value = e.target.value;
                            create_array_for_product ('time_delivery', e.target.value);
                        }
                    })
                    date_delivery_car_lux_pr.on('change', e => {
                        calc_price_by_product(create_array_for_product('','','all')).then();
                    })
                    //END REX CAR

                }
            });


        date_collect_pr.on ('change', (e) => {
            calc_price_by_product(create_array_for_product('','','all')).then();

            //VERIFICAR TEMPORADA
            verify_season (e.target.value).then (
                vs => {
                    date_delivery_pr.prop ('disabled', false);
                    if (sessionStorage.getItem ('same_hours') !== 'true') {
                        $ ('#time_delivery_pr').prop ('disabled', false);
                    }

                    let max_date = 0;
                    let min_date = 0;

                    if (vs.length > 0) {
                        max_date = parseInt (vs[0].max) + 1;
                        min_date = parseInt (vs[0].min) + 1;
                    } else {
                        max_date = parseInt (max_reservation) + 1;
                        min_date = parseInt (min_reservation) + 1;
                    }

                    flatpickr ("#date_delivery_pr", {
                        altInput: true,
                        altFormat: "d-m-Y",
                        dateFormat: "Y-m-d",
                        disableMobile: "true",
                        disable: date_disabled,
                        minDate: new Date (e.target.value).fp_incr (min_date),
                        maxDate: new Date (e.target.value).fp_incr (max_date),
                        locale: flatpick_lang
                    })
                }
            );
        })
        time_collect_pr.on ('change', (e) => {
            if (sessionStorage.getItem ('same_hours') === 'true') {
                time_delivery_pr[0].value = e.target.value;
                create_array_for_product ('time_delivery', e.target.value);
            }
        })
        date_delivery_pr.on('change', e => {
            calc_price_by_product(create_array_for_product('','','all')).then();
        })



        //ACCIONES PARA BUSTOUR
        destination_bustour_details.select2 ({
            theme: "bootstrap",
            placeholder: I18n.t ('global.main.form.select_destiny'),
            ajax: {
                url: `${location.origin}/${sessionStorage.getItem ('locale')}/destination_by_service`,
                data: {
                    url: url_dealer,
                    category: product_details.code,
                    seller: sessionStorage.getItem ('seller'),
                    form_type: product_details.type
                },
                type: "GET",
                processResults: function (data, params) {
                    let result = [];
                    data.map ((elem, key) => {

                        result.push ({
                            text: elem.name,
                            id: elem.code + '|' + elem.id,
                        });
                    })

                    return {
                        results: result
                    }

                }
            }
        }).on ('change', function (e) {
            let split_destination = e.target.value.split ("|")
            //LIMPIAR SELECT2
            date_collect_bustour_details.removeAttr ('disabled');
            amount_bustour_details[0].setAttribute ('value', 0);
            amount_bustour_details.removeAttr ('disabled');
            //FIN DE LIMPIEZA

            create_array_for_product ('product_type', product_details.type);
            create_array_for_product ('destination', split_destination[1]);
            create_array_for_product ('destination_id', split_destination[0]);
            create_array_for_product ('text_destination', $ (e.target).select2 ('data')[0].text);
            create_array_for_product ('category', product_details.code);
            create_array_for_product ('text_category', product_details.name);
            create_array_for_product ('dealer', product_details.dealerCode);
            create_array_for_product ('dealer_name', product_details.dealerName);
            create_array_for_product ('collect_place_text', $ (e.target).select2 ('data')[0].text);
            create_array_for_product ('category', product_details.code);
            create_array_for_product ('text_category', product_details.name);

            flatpickr ("#date_collect_bustour_details", {
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "Y-m-d",
                disableMobile: "true",
                disable: date_disabled,
                minDate: new Date ().fp_incr (parseInt (product_details.reservation)),
                locale: flatpick_lang
            });

        })
        //END BUSTOUR

        //ACCIONES PARA SHUTTLE
        destination_shuttle_details.select2 ({
            theme: "bootstrap",
            placeholder: I18n.t ('global.main.form.select_destiny'),
            ajax: {
                url: `${location.origin}/${sessionStorage.getItem ('locale')}/destination_by_service`,
                data: {
                    url: url_dealer,
                    category: product_details.code,
                    seller: sessionStorage.getItem ('seller'),
                    form_type: product_details.type
                },
                type: "GET",
                processResults: function (data, params) {
                    let result = [];
                    data.map ((elem, key) => {

                        result.push ({
                            text: elem.name,
                            id: elem.code + '|' + elem.id,
                        });
                    })

                    return {
                        results: result
                    }

                }
            }
        }).on ('change', function (e) {
            let split_destination = e.target.value.split ("|")
            //LIMPIAR SELECT2
            date_collect_shuttle_details.removeAttr ('disabled');
            amount_shuttle_details[0].setAttribute ('value', 0);
            amount_shuttle_details.removeAttr ('disabled');
            //FIN DE LIMPIEZA

            create_array_for_product ('product_type', product_details.type);
            create_array_for_product ('destination', split_destination[1]);
            create_array_for_product ('destination_id', split_destination[0]);
            create_array_for_product ('text_destination', $ (e.target).select2 ('data')[0].text);
            create_array_for_product ('category', product_details.code);
            create_array_for_product ('text_category', product_details.name);
            create_array_for_product ('dealer', product_details.dealerCode);
            create_array_for_product ('dealer_name', product_details.dealerName);
            create_array_for_product ('collect_place_text', $ (e.target).select2 ('data')[0].text);
            create_array_for_product ('category', product_details.code);
            create_array_for_product ('text_category', product_details.name);

            flatpickr ("#date_collect_shuttle_details", {
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "Y-m-d",
                disableMobile: "true",
                disable: date_disabled,
                minDate: new Date ().fp_incr (parseInt (product_details.reservation)),
                locale: flatpick_lang
            });

        })
        //END SHUTTLE

        //ACCIONES PARA MOTOS
        // destination_bikes_details.select2 ({
        //     theme: "bootstrap",
        //     placeholder: I18n.t ('global.main.form.select_destiny'),
        //     ajax: {
        //         url: `${location.origin}/${sessionStorage.getItem ('locale')}/destination`,
        //         dataType: "json",
        //         language: "es",
        //         data: function (params) {
        //             return {
        //                 form_type: product_details.type,
        //                 search: params.term,
        //                 page: params.page || 1
        //             }
        //
        //         },
        //         type: 'GET',
        //         processResults: function (data, params) {
        //             let result = [];
        //             params.page = params.page || 1;
        //
        //             data.rows.map ((elem, key) => {
        //                 result.push ({
        //                     id: elem.code + '|' + elem.id,
        //                     text: elem.name
        //                 })
        //             })
        //
        //             if (data.total > 10) {
        //
        //                 if ((parseInt (data.total) - (10 * parseInt (params.page))) > 1) {
        //                     return {
        //                         results: result,
        //                         "pagination": {
        //                             "more": true
        //                         }
        //                     }
        //                 } else {
        //                     return {
        //                         results: result,
        //                         "pagination": {
        //                             "more": false
        //                         }
        //                     }
        //                 }
        //
        //             } else {
        //                 return {
        //                     results: result,
        //                     "pagination": {
        //                         "more": false
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }).on ('change', function (e) {
        //     //LIMPIAR SELECT2
        //     collect_place_bikes_details.empty ();
        //     //FIN DE LIMPIEZA
        //
        //     let split = e.target.value.split ('|');
        //     destino = split[0]
        //     let destino_id = split[1]
        //     create_array_for_product ('product_type', product_details.type);
        //     create_array_for_product ('destination', destino);
        //     create_array_for_product ('destination_id', e.target.value.split ("|")[1]);
        //     create_array_for_product ('text_destination', $ (e.target).select2 ('data')[0].text);
        //     create_array_for_product ('category', product_details.code);
        //     create_array_for_product ('text_category', product_details.name);
        //     create_array_for_product ('collect_place_text', $ (e.target).select2 ('data')[0].text);

        if(product_details.type === 'bike'){
            $.ajax ({
                url: `${location.origin}/${sessionStorage.getItem ('locale')}/service_by_dealer`,
                data: {
                    form_type: product_details.type,
                    dealer: product_details.dealerCode
                },
                type: "GET",
                success: function (data) {
                    categories = data;
                }
            })
            collect_place_bikes_details.select2 ({
                theme: "bootstrap",
                language: "es",
                placeholder: I18n.t ('global.main.form.select_office'),
                ajax: {
                    url: `${location.origin}/${sessionStorage.getItem ('locale')}/office_by_destination`,
                    data: function (params) {
                        return {
                            type: product_details.type,
                            search: params.term,
                            page: params.page || 1
                            // destination: destino_id
                        }
                    },
                    type: "GET",
                    processResults: function (data, params) {
                        let result = [];
                        data.offices.map ((elem, key) => {
                            let aux = {
                                text: elem.dealer_name,
                                children: []
                            };


                            if (elem.offices.length > 0) {
                                elem.offices.map ((child, number) => {
                                    if (child.active) {
                                        destination_by_offices[child.code] = {
                                            destination_id: child.destination_id,
                                            destination_code: child.destination_code,
                                            destination_name: child.destination_name
                                        }
                                        aux.children.push ({
                                            id: child.code + '-dealer:' + elem.dealer_code + '-dealer_name:' + elem.dealer_name + '-full_time:' + child.full_time + '-open_time:' + child.open_time + '-close_time:' + child.close_time,
                                            text: child.name
                                        });
                                    }

                                })

                                result.push (aux);
                            }
                        })

                        if (result.length === 0) {
                            result.push ({
                                text: "No hay datos para mostrar",
                                children: []
                            });
                        }

                        return {
                            results: result
                        }
                    }
                }
            }).prop ("disabled", false).on ('change', function (e) {
                recogida = e.target.value;
                recogida = recogida.split ('-');

                create_array_for_product ('product_type', product_details.type);
                create_array_for_product ('destination', destination_by_offices[recogida[0]].destination_code);
                create_array_for_product ('destination_id', destination_by_offices[recogida[0]].destination_id);
                create_array_for_product ('text_destination',destination_by_offices[recogida[0]].destination_name);
                create_array_for_product ('category', product_details.code);
                create_array_for_product ('text_category', product_details.name);

                create_array_for_product ('pick_up_place', recogida[0]);
                create_array_for_product ('collect_place_text', $ (e.target).select2 ('data')[0].text);
                create_array_for_product ('drop_off_place', recogida[0]);
                create_array_for_product ('delivery_place_text', $ (e.target).select2 ('data')[0].text);
                create_array_for_product ('dealer', recogida[1].split ('dealer:')[1]);
                create_array_for_product ('dealer_name', recogida[2].split ('dealer_name:')[1]);

                let helper = $ (collect_place_bikes_details[0].parentNode).find ('.helper-text');
                let tootltip_button = $ (collect_place_bikes_details[0].parentNode).find ('.tooltip-button');
                let text_helper = '';

                categories_details (product_details.code).then (
                    category_details => {
                        let date_collect_bikes_details = $ ('#date_collect_bikes_details');
                        let time_collect_bikes_details = $ ('#time_collect_bikes_details');
                        let time_delivery_bikes_details = $ ('#time_delivery_bikes_details');
                        setSessionVar ('day_delay_rent', category_details.reservation_time, 'add', false);
                        setSessionVar ('day_min_rent', category_details.min_length_reservation, 'add', false);
                        setSessionVar ('day_max_rent', category_details.max_length_reservation, 'add', false);
                        // setSessionVar('category_description', category_details.description, 'add', false);
                        setSessionVar ('use_same_hours', 'true', 'add', false);

                        time_delivery_bikes_details.prop ('disabled', true);
                        time_delivery_bikes_details[0].value = time_collect_bikes_details[0].value;

                        create_array_for_product ('time_delivery', time_collect_bikes_details[0].value);


                        date_collect_bikes_details.prop ('disabled', false);
                        time_collect_bikes_details.prop ('disabled', false);


                        flatpickr ("#date_collect_bikes_details", {
                            altInput: true,
                            altFormat: "d-m-Y",
                            dateFormat: "Y-m-d",
                            disable: date_disabled,
                            disableMobile: "true",
                            minDate: new Date ().fp_incr (parseInt (category_details.reservation_time)),
                            maxDate: new Date ().fp_incr (180),
                            locale: flatpick_lang
                        });

                    }
                )

                $.ajax ({
                    url: `${location.origin}/${sessionStorage.getItem ('locale')}/load_modalities`,
                    data: {
                        form_type: product_details.type,
                        dealer: product_details.dealerCode,
                        product: product_details.code,
                        modality_name: 'PUBLICA'
                    },
                    type: 'GET',
                    success: function (data) {
                        if (data.success) {
                            let text_modality_bikes = $ ('#text_modality_bikes');
                            let helper = $ (text_modality_bikes[0].parentElement).find ('.helper-text');

                            if (parseInt (data.count) > 1) {
                                let data_result = [{
                                    id: 0,
                                    text: I18n.t ('global.main.form.select_modality')
                                }];
                                text_modality_bikes[0].classList.remove ('ocultar');
                                helper[0].innerHTML = "";

                                data.data.map ((index, elem) => {

                                    let text_modality_show = index.name.split (" ");
                                    text_modality_show.shift ();
                                    text_modality_show.join ("");

                                    data_result.push ({
                                        id: index.code + "|" + index.name + "|" + index.id,
                                        text: text_modality_show
                                    })
                                })

                                modality_bikes_details.select2 ({
                                    theme: "bootstrap",
                                    placeholder: I18n.t ('global.main.form.select_modality'),
                                    data: data_result
                                }).prop ("disabled", false).on ('change', function (e) {
                                    let modality_selected = e.target.value.split ("|");
                                    create_array_for_product ('modality_code', modality_selected[0])
                                    create_array_for_product ('modalities_name', modality_selected[1])
                                    calc_price_by_product(create_array_for_product('','','all')).then();
                                })
                            } else {
                                text_modality_bikes[0].classList.add ('ocultar');
                                helper[0].innerHTML = "<br> <p class='small'>No es necesario seleccionar una modalidad.</p>"
                                create_array_for_product ('modality_code', data.data[0].code)
                                create_array_for_product ('modalities_name', data.data[0].name)
                                calc_price_by_product(create_array_for_product('','','all')).then();
                            }

                        } else {
                            toastr.warning (data.msg, 'Warning');
                        }
                    }
                })


                let full_time = recogida[3].split ('full_time:')[1];
                if (full_time === "false") {
                    flatpickr (".timepicker", {
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        altFormat: "H:i",
                        time_24hr: true,
                        locale: flatpick_lang,
                        disableMobile: "true",
                        minTime: recogida[4].split ('open_time:')[1] + ":00",
                        maxTime: recogida[5].split ('close_time:')[1] + ":00",
                    })

                    let from = recogida[4].split ('open_time:')[1] + ":00am";
                    let to = (parseInt (recogida[5].split ("close_time:")[1]) - 12) + ":00pm";
                    text_helper = I18n.t ('global.main.form.work_by_hours', {from: from, to: to});
                    // text_helper = "Esta oficina trabaja en el horario de 0" + recogida[4].split('open_time:')[1] + ":00am a " + (parseInt(recogida[5].split("close_time:")[1]) - 12) + ":00pm ";
                } else {
                    flatpickr (".timepicker", {
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        altFormat: "H:i",
                        time_24hr: true,
                        locale: flatpick_lang,
                        disableMobile: "true",
                    })

                    text_helper = I18n.t ('global.main.form.work_all_day');

                }
                helper.text (text_helper);
                tootltip_button[0].classList.remove ('hide-tooltip');

                calc_price_by_product(create_array_for_product('','','all')).then();

            });
        }

        // });

        $ ('#date_collect_bikes_details').on ('change', (e) => {

            //VERIFICAR TEMPORADA
            verify_season (e.target.value).then (
                vs => {


                    let max_date = 0;
                    let min_date = 0;

                    if (vs.length > 0) {
                        max_date = parseInt (vs[0].max) + 1;
                        min_date = parseInt (vs[0].min) + 1;
                    } else {
                        max_date = parseInt (sessionStorage.getItem ('day_max_rent')) + 1;
                        min_date = parseInt (sessionStorage.getItem ('day_min_rent')) + 1;
                    }
                    $ ('#date_delivery_bikes_details').prop ('disabled', false);

                    flatpickr ("#date_delivery_bikes_details", {
                        altInput: true,
                        altFormat: "d-m-Y",
                        dateFormat: "Y-m-d",
                        disableMobile: "true",
                        disable: date_disabled,
                        minDate: new Date (e.target.value).fp_incr (min_date),
                        maxDate: new Date (e.target.value).fp_incr (max_date),
                        locale: flatpick_lang
                    });


                }
            );
            calc_price_by_product(create_array_for_product('','','all')).then();
        })
        $ ('#time_collect_bikes_details').on ('change', (e) => {
            let time_delivery = $ ('#time_delivery_bikes_details');
            time_delivery[0].value = e.target.value;
            create_array_for_product ('time_delivery', e.target.value);
        })

        $('#date_delivery_bikes_details').on('change', e => {
            calc_price_by_product(create_array_for_product('','','all')).then();
        })
        //END MOTOS

        // FORMULARIO PARA EL BUS TRAVEL

        $.ajax ({
            url: `${location.origin}/${sessionStorage.getItem ('locale')}/service_by_dealer`,
            data: {
                form_type: product_details.type,
                dealer: product_details.dealerCode
            },
            type: "GET",
            success: function (data) {
                categories = data;
            }
        })

        out_bus_details.select2 ({
            theme: "bootstrap",
            placeholder: I18n.t ('global.main.form.select_destiny'),
            ajax: {
                url: `${location.origin}/${sessionStorage.getItem ('locale')}/destination_by_service`,
                dataType: "json",
                language: "es",
                data: function (params) {
                    return {
                        url: url_dealer,
                        category: product_details.code,
                        seller: sessionStorage.getItem ('seller'),
                        search: params.term,
                        page: params.page || 1,
                        form_type: product_details.type
                    }

                },
                type: 'GET',
                processResults: function (data, params) {
                    let result = [];
                    data.map ((elem, key) => {
                        result.push ({
                            id: elem.code + '|' + elem.id,
                            text: elem.name
                        })
                    })

                    return {
                        results: result,
                        "pagination": {
                            "more": false
                        }
                    }
                }
            }
        }).on ('change', function (e) {
            let destination_text = $ (e.target).select2 ('data')[0].text;
            destination_bus_details.empty ().prop ('disabled', true);
            bus_location_details.removeAttr ('disabled');
            date_collect_bus_details.empty ();
            date_collect_bus_details.prop ('disabled', true);

            create_array_for_product ('destination', e.target.value.split ('|')[0]);
            create_array_for_product ('destination_id', e.target.value.split ("|")[1]);
            create_array_for_product ('text_destination', destination_text);
            create_array_for_product ('product_type', product_details.type);

            create_array_for_product ('category', product_details.code);
            create_array_for_product ('text_category', product_details.name);
            create_array_for_product ('dealer', product_details.dealerCode);
            create_array_for_product ('dealer_name', product_details.dealerName);
            create_array_for_product ('collect_place_text', $ (e.target).select2 ('data')[0].text);
            create_array_for_product ('category', product_details.code);
            create_array_for_product ('text_category', product_details.name);

            $.ajax ({
                url: `${location.origin}/${sessionStorage.getItem ('locale')}/service_by_destination`,
                data: {
                    destination: e.target.value.split ("|")[1],
                    form_type: product_details.type
                },
                type: 'GET',
                success: function (data) {
                    if (data.success) {

                        let dealer = data.dealer;
                        let contract = data.contract;

                        create_array_for_product ('dealer', dealer.code);
                        create_array_for_product ('dealer_name', dealer.name);
                        create_array_for_product ('collect_place_text', destination_text);

                        create_array_for_product ('contract_code_number', contract.code_number);
                        create_array_for_product ('contract_code', contract.code);


                        categories_details (product_details.code).then (
                            category_details => {
                                date_collect_bus_details.removeAttr ('disabled');
                                destination_bus_details.empty ();
                                destination_bus_details.removeAttr ('disabled');
                                time_collect_bus_details.removeAttr ('disabled');

                                create_array_for_product ('day_min_rent', category_details.min_reservation_amount);
                                create_array_for_product ('day_max_rent', category_details.max_reservation_amount);

                                flatpickr ("#date_collect_bus_details", {
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    disableMobile: "true",
                                    disable: date_disabled,
                                    minDate: new Date ().fp_incr (parseInt (category_details.reservation_time)),
                                    locale: flatpick_lang
                                });

                                flatpickr ("#time_collect_bus_details", {
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: "H:i",
                                    altFormat: "H:i",
                                    time_24hr: true,
                                    locale: flatpick_lang,
                                    disableMobile: "true"
                                });


                                $.ajax ({
                                    url: location.origin + '/modalities_by_product',
                                    data: {
                                        dealer: product_details.dealerCode,
                                        product: product_details.code
                                    },
                                    type: 'GET',
                                    success: function (data_modalities) {
                                        if (parseInt (data_modalities.count) > 1) {
                                            let data_result = [{
                                                id: 0,
                                                text: I18n.t ('global.main.form.select_destiny')
                                            }];

                                            data_modalities.data.map ((elem, index) => {

                                                data_result.push ({
                                                    id: elem.code + "|" + elem.name + "|" + elem.id,
                                                    text: elem.name
                                                })
                                            })

                                            destination_bus_details.select2 ({
                                                theme: "bootstrap",
                                                placeholder: I18n.t ('global.main.form.select_modality'),
                                                data: data_result
                                            }).prop ("disabled", false).on ('change', function (e) {
                                                let modality_selected = e.target.value.split ("|");
                                                create_array_for_product ('modality_code', modality_selected[0])
                                                create_array_for_product ('modalities_name', modality_selected[1])
                                            })
                                        }
                                    }
                                })


                            }
                        )

                    } else {
                        toastr.error (data.msg);
                    }


                }
            })


        })
        // FIN DE FORMULARIO PARA EL BUS TRAVEL

    }

    connect () {

    }


}